window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
        window.triggerLead();
});

window.addEventListener('heyflow-init', async (event) => {
    window._udata= {
        n: "FR"
    };
    await setIp();
})

async function setIp() {
  const ipInput = document.querySelector('input[data-label="ip"]');
  let requestOptions = { method: 'GET', redirect: 'follow' };

  let res = await fetch("https://europe-southwest1-react-lp-387513.cloudfunctions.net/ip_retain", requestOptions)
  let ip = await res.text()

  ipInput.value = ip;
  document.querySelector('input[data-label="page-url"]').value = window.location.href;
}

// Get a reference to all elements with the 'scale-option' class
const elements = document.querySelectorAll('.scale-option');


// Add a click event listener to each element
elements.forEach(element => {
  element.addEventListener('click', () => {
    // Remove the 'active' class from all elements
    elements.forEach(el => {
      el.classList.remove('active');
    });

    // Add the 'active' class to the clicked element
    element.classList.add('active');
  });
});
